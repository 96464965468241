import axiosIns from "@axios";
import router from "@/router";
import Swal from "sweetalert2";
import store from "@/store";
import {
    getUserData
} from "@/auth/utils";

export default {
    state: {
        isCodeDialog: false,
        codesActivePage: [],
        codesPageLength: 10,
        codesFirstPage: 1,
        codes: [],
        mainCodesList: [],
        packages: [],
        packagesActivePage: [],
        packagesPageLength: 10,
        packagesFirstPage: 1,
        packageTypes: [],
        packagesList: [],
        mainPackagesList: [],
        codeForm: {
            id: 0,
            code: "",
            price: null,
            discountRate: null,
            packagesIds: []
        },
        packageForm: {
            id: 0,
            name: "",
            description: "",
            price: null,
            startDate: new Date(),
            endDate: null,
            isHidden: false,
            packageType: 0,
            packageUnits: []
        },
        subjectsUnits: []
    },
    getters: {
        isCodeDialog(state) {
            return state.isCodeDialog;
        },
        codes(state) {
            return state.codes;
        },
        packages(state) {
            return state.packages;
        },
        packageTypes(state) {
            return state.packageTypes;
        },
        packagesList(state) {
            return state.packagesList;
        },
        codeForm(state) {
            return state.codeForm;
        },
        packageForm(state) {
            return state.packageForm;
        },
        subjectsUnits(state) {
            return state.subjectsUnits;
        },
        mainCodesList(state) {
            return state.mainCodesList;
        },
        codesActivePage(state) {
            return state.codesActivePage;
        },
        codesPageLength(state) {
            return state.codesPageLength;
        },
        codesFirstPage(state) {
            return state.codesFirstPage;
        },
        mainPackagesList(state) {
            return state.mainPackagesList;
        },
        packagesActivePage(state) {
            return state.packagesActivePage;
        },
        packagesFirstPage(state) {
            return state.packagesFirstPage;
        },
        packagesPageLength(state) {
            return state.packagesPageLength;
        },
    },
    mutations: {
        OPEN_CODE_DIALOG(state, payload) {
            state.isCodeDialog = payload;
            state.codeForm = {
                id: 0,
                code: "",
                price: null,
                discountRate: null,
                packagesIds: []
            };
        },
        UPDATE_CODES_PAGINATION(state, currentPage) {
            store
                .dispatch("app/pageChanged", {
                    currentPage: currentPage,
                    pageLength: state.codesPageLength,
                    items: state.mainCodesList
                })
                .then(data => {
                    state.codesActivePage = data;
                    state.codesFirstPage = currentPage;
                });
        },
        GET_CODES_ITEMS(state, payload) {
            state.codes = payload;
            state.mainCodesList = [...payload];
            store.dispatch("updateCodesPagination", 1);
        },
        PACKAGES_LIST(state, payload) {
            state.packagesList = payload;
        },
        GENERATE_CODE(state, payload) {
            state.codes.unshift(payload);
            state.mainCodesList.unshift(payload);
            state.codeForm = {
                id: 0,
                code: "",
                price: null,
                discountRate: null,
                packagesIds: []
            };
            store.dispatch("updateCodesPagination", 1);
        },
        UPDATE_PACKAGES_PAGINATION(state, currentPage) {
            store
                .dispatch("app/pageChanged", {
                    currentPage: currentPage,
                    pageLength: state.packagesPageLength,
                    items: state.mainPackagesList
                })
                .then(data => {
                    state.packagesActivePage = data;
                    state.packagesFirstPage = currentPage;
                });
        },
        GET_PACKAGES_ITEMS(state, payload) {

            state.packages = payload;
            state.mainPackagesList = [...payload];
            store.dispatch("updatePackagesPagination", 1);
        },
        CREATE_PACKAGE(state, payload) {
            state.packageForm = {
                id: 0,
                name: "",
                description: "",
                price: null,
                startDate: new Date(),
                endDate: null,
                isHidden: false,
                packageType: 0,
                packageUnits: []
            }
            state.subjectsUnits = payload.subjectsUnits
            state.packageTypes = payload.packageTypes;
            router.push("/codes-packages/packages/0");
            store.dispatch("updatePackagesPagination", 1);
        },
        GET_PACKAGE_DETAILS(state, payload) {
            Object.assign(state.packageForm, payload.packageDto);
            state.subjectsUnits = payload.subjectsUnits;
            state.packageTypes = payload.packageTypes
            if (payload.packageDto && payload.packageDto.packageUnits) {
                payload.packageDto.packageUnits.forEach((selectedUnit) => {
                    for (let i = 0; i < state.subjectsUnits.length; i++) {
                        let flag = 0;
                        for (let j = 0; j < state.subjectsUnits[i].units.length; j++) {
                            if (state.subjectsUnits[i].units[j].unitId == selectedUnit.unitId) {
                                Object.assign(state.subjectsUnits[i].units[j], {
                                    unitId: selectedUnit.unitId,
                                    unitName: selectedUnit.unitName,
                                    price: selectedUnit.price,
                                    teacherRate: (!selectedUnit.teacherRate ? state.subjectsUnits[i].units[j].teacherRate : selectedUnit.teacherRate),
                                    selected: true
                                })
                                flag = 1;
                                break;
                            }
                            if (flag) {
                                break;
                            }
                        }
                    }
                })
            }
        },
        CANT_REMOVE() {
            Swal.fire({
                title: "يمكن الحذف !",
                text: "حدث خطأ، الرجاء إعادة المحاولة.",
                icon: "error",
                showConfirmButton: false,
                timer: 4000
            });
        },
        REMOVE_PACKAGE(state, payload) {
            console.log(payload)
            Swal.fire({
                title: "تم الحذف !",
                //text: `تم حذف ${payload.name}.`,
                icon: "success",
                confirmButtonText: "تم"
            });
            router.push(`/codes-packages/packages`)
        },
        REMOVE_CODES(state, payload) {
            console.log(payload)
            let MapOfIds = new Map();
            var idx;
            var tempList = [];
            for (idx = 0; idx < payload.ids.length; idx++) {
                MapOfIds.set(payload.ids[idx], 1);
            }
            for (idx = 0; idx < state.mainCodesList.length; idx++) {
                if (MapOfIds.has(state.mainCodesList[idx].id) === false) {
                    tempList.push(state.mainCodesList[idx]);
                }
            }
            state.mainCodesList = tempList;
            state.codesActivePage = state.mainCodesList;

            store.dispatch("updateCodesPagination", 1)
            if(payload.type == 0){
                Swal.fire({
                    title: "تم الحذف !",
                    text: `تم حذف الرموز المحددة.`,
                    icon: "success",
                    confirmButtonText: "تم"
                });
            }
            else{
                Swal.fire({
                    title: "تحذير !",
                    text: `لم يتم حذف بعض الرموز لأنها مفعلة.`,
                    icon: "warning",
                    confirmButtonText: "تم"
                });
            }
            
        },
        REMOVE_PACKAGES(state , payload){
            console.log(payload)
            let MapOfIds = new Map();
            var idx;
            var tempList = [];
            for (idx = 0; idx < payload.ids.length; idx++) {
                MapOfIds.set(payload.ids[idx], 1);
            }
            for (idx = 0; idx < state.mainPackagesList.length; idx++) {
                if (MapOfIds.has(state.mainPackagesList[idx].id) === false) {
                    tempList.push(state.mainPackagesList[idx]);
                }
            }
            state.mainPackagesList = tempList;
            state.packagesActivePage = state.mainPackagesList;

            store.dispatch("updatePackagesPagination", 1)
            if(payload.type == 0){
                Swal.fire({
                    title: "تم الحذف !",
                    text: `تم حذف الحزم المحددة.`,
                    icon: "success",
                    confirmButtonText: "تم"
                });
            }
            else{
                Swal.fire({
                    title: "تحذير !",
                    text: `لم يتم حذف بعض الحزم لأنها مرتبطة بأكواد.`,
                    icon: "warning",
                    confirmButtonText: "تم"
                });
            }
        }
    },
    actions: {
        toggleCodeDialog(context, payload) {
            context.commit("OPEN_CODE_DIALOG", payload);
        },
        updateCodesPagination(context, currentPage) {
            context.commit("UPDATE_CODES_PAGINATION", currentPage)
        },
        async fetchCodes(context) {
            const {
                data
            } = await axiosIns.get("/Code/GetCodes");
            context.commit("GET_CODES_ITEMS", data);
        },
        async setCode(context, payload) {
            const {
                data
            } = await axiosIns.post("/Code/GenerateCode", {
                Id: payload.id,
                Price: +payload.price,
                DiscountRate: +payload.discountRate,
                PosId: +getUserData().id,
                PackagesIds: payload.packagesIds
            });
            context.commit("GENERATE_CODE", data);
        },
        updatePackagesPagination(context, currentPage) {
            context.commit("UPDATE_PACKAGES_PAGINATION", currentPage)
        },
        async fetchPackages(context) {
            const {
                data
            } = await axiosIns.get("/Package/GetPackages");
            context.commit("GET_PACKAGES_ITEMS", data);
        },
        async getPackagesList(context) {
            const {
                data
            } = await axiosIns.get("/Package/PackagesList");
            context.commit("PACKAGES_LIST", data);
        },
        async createPackage(context) {
            const {
                data
            } = await axiosIns.get(
                `/Package/PackageDetails?packageId=0`
            );

            context.commit("CREATE_PACKAGE", data);
        },
        async fetchPackageDetails(context, payload) {
            const {
                data
            } = await axiosIns.get(
                `/Package/PackageDetails?packageId=${payload}`
            );
            context.commit("GET_PACKAGE_DETAILS", data);
        },
        async setPackage(context, payload) {
            Promise.all(
                payload.packageUnits.map((unit) => {
                    delete unit.selected
                })
            ).then(async () => {
                console.log(payload)
                const {
                    data
                } = await axiosIns.post("/Package/SetPackage", {
                    Id: payload.id,
                    Name: payload.name,
                    Description: payload.description,
                    PackageType: payload.packageType,
                    StartDate: new Date(payload.startDate),
                    EndDate: new Date(payload.endDate),
                    Price: +payload.price,
                    IsHidden: payload.isHidden,
                    packageUnits: payload.packageUnits
                });
                context.commit("GENERATE_CODE", data);
            })
        },
        async removePackage(context, payload) {
            console.log(payload);
            const {
                data
            } = await axiosIns.put(
                "/Package/RemovePackages",
                payload
            );
            console.log(data)
            if (!data  || data.type == 1) {
                context.commit("CANT_REMOVE");
            } else {
                context.commit("REMOVE_PACKAGE", data);
                
            }
        },
        async removePackages(context, payload) {
            const { data } = await axiosIns.put("/Package/RemovePackages", payload);

            if (!data) {
                context.commit("CANT_REMOVE");
            } else {
                context.commit("REMOVE_PACKAGES", data);
            }
        },
        async filterCodes({
            state
        }) {
            state.mainCodesList = await store.dispatch('app/filterItems', {
                items: 'codes',
                key: 'code'
            })
        },
        async filterPackages({
            state
        }) {
            state.mainPackagesList = await store.dispatch('app/filterItems', {
                items: 'packages',
                key: 'name'
            })
        },
        async removeCodes(context, payload) {
            const { data } = await axiosIns.put("/Code/RemoveCodes", payload);

            if (!data) {
                context.commit("CANT_REMOVE");
            } else {
                context.commit("REMOVE_CODES", data);
            }
        },

    }
};
